import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import moment from "moment";

import config from "../../../firebaseConfig";

class Firebase {
  constructor() {
    app.initializeApp(config.production);
    // admin.initializeApp(config.staging);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.documentId = app.firestore.FieldPath.documentId();
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */
    // this.admin = app.admin.auth();
    this.auth = app.auth();
    this.realtimeDB = app.database();
    this.db = app.firestore();
    this.functions = app.functions();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUser = (uid, email, password) => {
    console.log("[doCreateUser]", uid, email, password);
    const createAccount = this.functions.httpsCallable("createUserAccount");
    return createAccount({ uid, email, password });
  };

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  // doSendEmailVerification = () =>
  //   this.auth.currentUser.sendEmailVerification({
  //     url: window.location.href,
  //   });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  agreeToTermsOfService = (uid) =>
    this.db
      .doc(`users/${uid}`)
      .update({ acceptedTermsOfService: moment.utc().valueOf() });

  // *** User API ***

  user = (uid) => this.db.doc(`users/${uid}`);

  users = () => this.db.collection("users");

  makeUserFacilitator = (uid) =>
    this.db
      .doc(`users/${uid}`)
      .update({ roles: this.fieldValue.arrayUnion("FACILITATOR") });

  makeUserTeamLeader = (uid) =>
    this.db
      .doc(`users/${uid}`)
      .update({ roles: this.fieldValue.arrayUnion("FACILITATOR") });

  addTeamToUser = (uid, teamId, ministryId) =>
    this.db.doc(`users/${uid}`).update({
      teams: this.fieldValue.arrayUnion(teamId),
      ministries: this.fieldValue.arrayUnion(ministryId),
    });
  // removeTeamFromUser = (userUid, teamId) =>
  //   this.db.doc(`users/${userUid}`).update({
  //     teams: this.FieldValue.arrayRemove(teamId), // Here add your Element ID which you want to remove it.
  //   });

  getUserByEmail = (email) =>
    this.db.collection("users").where("email", "==", email).get();

  // *** Team API ***

  team = (uid) => this.db.doc(`teams/${uid}`);

  teams = () => this.db.collection("teams");

  addInvites = (teamId, quantity) =>
    this.db.doc(`teams/${teamId}`).update({
      invites: this.fieldValue.increment(+quantity),
    });

  addTeamLeader = (teamId, userId) =>
    this.db
      .doc(`teams/${teamId}`)
      .update({ leaders: this.fieldValue.arrayUnion(userId) });

  editTeamName = (name, teamId) => {
    console.log("editTeamName", name, teamId);
    return this.db.doc(`teams/${teamId}`).update({
      name,
    });
  };

  disableTeam = (teamId) => {
    return this.db.doc(`teams/${teamId}`).update({ country: null });
  };

  enableTeam = (teamId, country) => {
    return this.db.doc(`teams/${teamId}`).update({ country: [country] });
  };

  // *** Resource API ***

  resource = (resourceId) => this.db.doc(`resources/${resourceId}`);

  approveResource = (resourceId) =>
    this.db.doc(`resources/${resourceId}`).update({
      approved: true,
    });

  resources = () => this.db.collection("resources");

  addFacilitatorToResourceData = (resourceId, facilitatorId) =>
    this.db.doc(`resources/${resourceId}`).update({
      facilitators: this.fieldValue.arrayUnion(facilitatorId),
    });
  removeFacilitatorToResourceData = (resourceId, facilitatorId) =>
    this.db.doc(`resources/${resourceId}`).update({
      facilitators: this.fieldValue.arrayRemove(facilitatorId),
    });

  addMinistryToResourceData = (resourceId, ministryId) =>
    this.db.doc(`resources/${resourceId}`).update({
      ministries: this.fieldValue.arrayUnion(ministryId),
    });
  removeMinistryToResourceData = (resourceId, ministryId) =>
    this.db.doc(`resources/${resourceId}`).update({
      ministries: this.fieldValue.arrayRemove(ministryId),
    });

  // *** Pins API ***

  regionPins = (regionId) =>
    this.db
      .collection("regionLocations")
      .doc(regionId)
      .collection("mapPins")
      .doc("pins");

  // *** Reports API ***

  report = (reportId) => this.db.collection("reports").doc(reportId);

  reports = () => this.db.collection("reports");

  timeBasedReport = (reportId, time) =>
    this.db
      .collection("timeBasedReports")
      .doc(time)
      .collection("reports")
      .doc(reportId);

  restrictedRegions = (countryCode) =>
    this.db.collection("restrictedRegions").doc(countryCode);

  allRestrictedRegions = () =>
    this.db.collection("restrictedRegions");

  topojson = (countryCode, region) =>
    this.realtimeDB.ref("websiteTopojson/" + countryCode + "/" + region);

  regionNameData = (regionId) =>
    this.realtimeDB.ref("regionLabelData/" + regionId);
  // *** Ministry API ***

  ministry = (ministryId) => this.db.doc(`ministries/${ministryId}`);

  ministries = () => this.db.collection("ministries");

  addFacilitatorToMinistryData = (ministryId, facilitatorId) =>
    this.db.doc(`ministries/${ministryId}`).update({
      facilitators: this.fieldValue.arrayUnion(facilitatorId),
    });
  removeFacilitatorFromMinistryData = (ministryId, facilitatorId) =>
    this.db.doc(`ministries/${ministryId}`).update({
      facilitators: this.fieldValue.arrayRemove(facilitatorId),
    });

  addMinistryIdToFacilitatorData = (ministryId, facilitatorId) =>
    this.db.doc(`users/${facilitatorId}`).update({
      ministries: this.fieldValue.arrayUnion(ministryId),
    });

  removeMinistryIdFromFacilitatorData = (ministryId, facilitatorId) =>
    this.db.doc(`users/${facilitatorId}`).update({
      ministries: this.fieldValue.arrayRemove(ministryId),
    });

  removeMinistryFromUser = async (userUid, ministryId) =>
    this.db.doc(`users/${userUid}`).update({
      ministries: this.fieldValue.arrayRemove(ministryId),
    });
  removeTeamFromUser = async (userUid, teamId) =>
    this.db.doc(`users/${userUid}`).update({
      teams: this.fieldValue.arrayRemove(teamId),
    });

  removeTeamFromMinistry = async (teamId) => {
    this.db.doc(`teams/${teamId}`).update({
      ministryId: this.fieldValue.delete(),
      country: this.fieldValue.delete(),
    });
  };

  // ** Testimonies ** //

  testimonies = () => this.db.collection("testimonies");

  // ** CREATE UNIQUE ID ** //
  createUniqueUid = async (type) => {
    const uid = Array(28)
      .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");
    const uidExists = await this.db
      .doc(`${type}/${uid}`)
      .get()
      .then((doc) => doc.exists);
    while (uidExists) {
      console.log(`uid ${uid} exists in "${type}" already!`);
      this.createUniqueUid(type);
    }
    return uid;
  };

  uploadReport = (id, data) => {
    return this.db.collection("reports").doc(id).set(data);
  };

  update = (region) => this.db.doc(`updates/${region}`);

  updates = () => this.db.collection(`updates`);

  download = (userId) => this.db.doc(`downloads/${userId}`);
}

let firebase;

function getFirebase(app, auth, database) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database);
  }

  return firebase;
}

export default getFirebase;
